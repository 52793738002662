@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: Agrandir;
  src: url('./content/fonts/Agrandir-Regular.ttf');
}

@font-face {
  font-family: PoppinsB;
  src: url('./content/fonts/Poppins-Bold.ttf');
}

@font-face {
  font-family: PoppinsM;
  src: url('./content/fonts/Poppins-Medium.ttf');
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: PoppinsM, sans-serif;
}

:root {
  --primary: #004aad;
  --orange: #fe904d;
  --green: #c1ff72;
  --skypark: #7159e3;
  --green: #c1ff72;
  --red: #ec1c24;
  --yellow: #fedd59;
  --purple: #7159e3;
}

.font-mybold {
  font-family: PoppinsB, sans-serif;
}

.input {
  @apply w-full border-2 border-black rounded-full px-3 py-2
}

.select {
  @apply cursor-pointer w-full bg-[transparent] border-2 border-black rounded-full px-3 py-2 text-black
}

.text-balance {
  text-wrap: balance;
}

button {
  font-family: PoppinsB, sans-serif;
}

.no-scroll-bar::-webkit-scrollbar {
  display: none;
}
.no-scroll-bar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}